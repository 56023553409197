import { ISharpGatsbyImageArgs, StaticImage } from "gatsby-plugin-image"
import React from "react"

import { Slider } from "../../../components"

import CoverTypeButton from "./CoverTypeButton"

import { PRODUCT_COLOR } from "."

const PATH = "../../../assets/images/products/platinum-plus-funeral-benefit/"

const Coverage = (): JSX.Element => {
  const ImageProperties: {
    alt: string
    placeholder: ISharpGatsbyImageArgs["placeholder"]
    quality: number
  } = {
    alt: "",
    placeholder: "blurred",
    quality: 100,
  }

  return (
    <Slider
      title="Funeral Coverage Tailored for Families"
      color={PRODUCT_COLOR}
      className="slider"
      content={[
        {
          image: (
            <StaticImage src={`${PATH}benefits-1.png`} {...ImageProperties} />
          ),
          topic: "Funeral Coverage for Dignified Burials",
          label:
            "One of the primary objectives of the Platinum Plus Funeral Benefit is to provide dignified farewells. Your family deserves a burial that respects their memory, and this product ensures that financial concerns don't get in the way.",
        },
        {
          image: (
            <StaticImage src={`${PATH}benefits-2.png`} {...ImageProperties} />
          ),
          topic: "Affordable Funeral Insurance",
          label:
            "We recognize the importance of affordable funeral insurance. Our product is designed to offer comprehensive coverage without straining your budget. From as little as R215, you can give your family maximum benefits during challenging times.",
        },
        {
          image: (
            <StaticImage src={`${PATH}benefits-3.png`} {...ImageProperties} />
          ),
          topic: "Funeral Insurance for Senior Citizens",
          label:
            "This product also caters to the unique needs of senior citizens. They can rest assured that their funeral expenses are covered, offering both them and their families peace of mind.",
        },
      ]}
    />
  )
}

export default Coverage
