import { BasicStyledButtonProps } from "../../../elements/StyledButton"
import theme from "../../../theme"
export const PRODUCT_COLOR = theme.palette.custom.yellow

export { default as Article } from "./Article"
export { default as Benefits } from "./Benefits"
export { default as CoverTypeModal } from "./CoverTypeModal"
export { default as OptionalBenefits } from "./OptionalBenefits"
export { default as Perks } from "./Perks"
export { default as SEO } from "./SEO"
export { default as Options } from "./Options"
export { default as Coverage } from "./Coverage"
export { default as Conclusion } from "./Conclusion"

export interface ToggleModalProps extends BasicStyledButtonProps {
  toggleModal: () => void
  coverType?: string
  label?: string
}
