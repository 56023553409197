import React from "react"
import { StyledButton } from "../../../elements"

import { PRODUCT_COLOR, ToggleModalProps } from "."

const CoverTypeButton = ({
  toggleModal,
  label,
}: ToggleModalProps): JSX.Element => (
  <StyledButton
    className="coverButton"
    filled
    label={label || "Build My Cover"}
    buttonColor={PRODUCT_COLOR}
    textColor="#fff"
    onClick={toggleModal}
  />
)

export default CoverTypeButton
