import { ISharpGatsbyImageArgs, StaticImage } from "gatsby-plugin-image"
import React from "react"

import { Slider } from "../../../components"

import CoverTypeButton from "./CoverTypeButton"

import { PRODUCT_COLOR } from "."

const PATH = "../../../assets/images/products/platinum-plus-funeral-benefit/"

const Options = (): JSX.Element => {
  const ImageProperties: {
    alt: string
    placeholder: ISharpGatsbyImageArgs["placeholder"]
    quality: number
  } = {
    alt: "",
    placeholder: "blurred",
    quality: 100,
  }

  return (
    <Slider
      title="Benefits of Platinum Plus Funeral Product"
      color={PRODUCT_COLOR}
      className="slider"
      content={[
        {
          image: (
            <StaticImage src={`${PATH}benefits-1.png`} {...ImageProperties} />
          ),
          topic: "Two Types of Covers",
          label:
            "The Platinum Plus Funeral Benefit offers two types of coverage: The Termed Plan: Provides coverage until the policyholder reaches the age of 75. The Whole Life Plan: Offers coverage until the death of the policyholder. This versatility allows you to choose the coverage option that best suits your needs and situation.",
        },
        {
          image: (
            <StaticImage src={`${PATH}benefits-2.png`} {...ImageProperties} />
          ),
          topic: "Maximum Coverage of Up to R60,000",
          label:
            "With the Platinum Plus Funeral Benefit, you can get a maximum cover of up to R60,000 for the main member and spouse. This substantial coverage ensures that your family is financially secure during challenging times.",
        },
      ]}
      reverse={true}
    />
  )
}

export default Options
