import React from "react"
import styled from "styled-components"

import { ProductArticleExtraContent } from "../../../components"

import CoverTypeButton from "./CoverTypeButton"

import { PRODUCT_COLOR, ToggleModalProps } from "."

const Article = ({ toggleModal }: ToggleModalProps): JSX.Element => {
  return (
    <Container>
      <ProductArticleExtraContent
        color={PRODUCT_COLOR}
        title="Platinum Plus Funeral Benefit"
        subTitle="Discover the Platinum Plus Funeral Benefit, offering enhanced funeral expense cover for the main member, spouse, children, and extended family. Secure affordable funeral insurance with a range of benefits for dignified burials."
        topic="Elevating Funeral Cover for Your Loved Ones"
        button={
          <CoverTypeButton toggleModal={toggleModal} label="Get Coverage" />
        }
      >
        <p>
          The passing of a loved one is a challenging time, and ensuring that
          they receive a dignified farewell is a significant part of the
          grieving process. At Workerslife, we understand the importance of
          providing comprehensive funeral coverage to give your family maximum
          benefits during this difficult period. That&apos;s why we offer the
          Platinum Plus Funeral Benefit, designed to enhance funeral expense
          coverage for the main member, spouse, up to six children, and extended
          family members. This affordable funeral insurance product provides
          peace of mind and ensures dignified burials.
        </p>
      </ProductArticleExtraContent>
    </Container>
  )
}

export default Article

const Container = styled.div`
  @media screen and (min-width: ${props => props.theme.breakpoints.lg}) {
    padding-bottom: 90px;
  }
`
