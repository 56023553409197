import React, { useEffect, useState } from "react"
import styled from "styled-components"

import { usePreventScroll } from "../../hooks"
import {
  Article,
  Benefits,
  CoverTypeModal,
  SEO,
  PRODUCT_COLOR,
  Coverage,
  Options,
  Conclusion,
} from "../../sections/products/platinum-plus-funeral"

const PlatinumPlusFuneralBenefit: React.FC = () => {
  const CoverButtonProperties = {
    filled: true,
    buttonColor: PRODUCT_COLOR,
    textColor: "#fff",
  }

  const [showModal, setShowModal] = useState(false)

  const toggleModal = () => {
    setShowModal(show => !show)
  }

  const preventScroll = usePreventScroll()

  useEffect(() => {
    preventScroll(showModal)
  }, [showModal])

  return (
    <PageWrapper className="touchFooter">
      <SEO />
      <CoverTypeModal
        buttonProperties={CoverButtonProperties}
        toggleModal={toggleModal}
        showModal={showModal}
      />
      <Article toggleModal={toggleModal} />
      <Coverage />
      <Options />
      <Benefits toggleModal={toggleModal} />
      <Conclusion toggleModal={toggleModal} />
    </PageWrapper>
  )
}

export default PlatinumPlusFuneralBenefit

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 40px;
`
