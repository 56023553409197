import { ISharpGatsbyImageArgs, StaticImage } from "gatsby-plugin-image"
import React from "react"

import { Slider } from "../../../components"

import CoverTypeButton from "./CoverTypeButton"

import { PRODUCT_COLOR, ToggleModalProps } from "."

const PATH = "../../../assets/images/products/platinum-plus-funeral-benefit/"

const Benefits = ({ toggleModal }: ToggleModalProps): JSX.Element => {
  const ImageProperties: {
    alt: string
    placeholder: ISharpGatsbyImageArgs["placeholder"]
    quality: number
  } = {
    alt: "",
    placeholder: "blurred",
    quality: 100,
  }

  return (
    <Slider
      title="Benefits of Platinum Plus Funeral Product"
      color={PRODUCT_COLOR}
      className="slider"
      button={<CoverTypeButton toggleModal={toggleModal} />}
      content={[
        {
          image: (
            <StaticImage src={`${PATH}benefits-1.png`} {...ImageProperties} />
          ),
          topic: "Repatriation Benefit",
          label:
            "Our product includes a repatriation benefit of R7,500 per claim, up to a maximum of R20,000 per year. This benefit ensures that your loved ones can be transported home for a dignified burial, even if they're far away.",
        },
        {
          image: (
            <StaticImage src={`${PATH}benefits-2.png`} {...ImageProperties} />
          ),
          topic: "Premium Waiver on Death",
          label:
            "In the unfortunate event of the main member's passing, we understand the financial impact. That's why we offer a premium waiver of 60 months to provide relief during this challenging period.",
        },
        {
          image: (
            <StaticImage src={`${PATH}benefits-3.png`} {...ImageProperties} />
          ),
          topic: "Cash Benefits for Children",
          label:
            "Your children receive up to R20,000 in cash, depending on their age. This financial support ensures they can focus on their future without worrying about expenses.",
        },
        {
          image: (
            <StaticImage src={`${PATH}benefits-4.png`} {...ImageProperties} />
          ),
          topic: "Vehicle Assist Benefit",
          label:
            "In challenging situations, our product provides vehicle assistance for a 3-day period. This ensures that you have the support you need in transportation.",
        },
        {
          image: (
            <StaticImage src={`${PATH}benefits-5.png`} {...ImageProperties} />
          ),
          topic: "Once-off Airtime Benefit",
          label:
            "We understand that communication is crucial during such times. That's why we offer a once-off airtime benefit of R250, ensuring that you can stay connected when you need it the most.",
        },
      ]}
    />
  )
}

export default Benefits
