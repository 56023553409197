import React from "react"
import { SEO } from "../../../components"
import { ProductJsonLd } from "../GoogleSEO"

const PRODUCT_NAME = "Platinum Plus Funeral Benefit"
const DESCRIPTION =
  "Secure your family's future with our Platinum Plus Funeral Benefit. Comprehensive coverage for dignified funerals and financial peace of mind."

const ProductSEO = (): JSX.Element => (
  <>
    <SEO title={PRODUCT_NAME} description={DESCRIPTION} />
    <ProductJsonLd
      name={PRODUCT_NAME}
      description={DESCRIPTION}
      lowPrice={215}
    />
  </>
)

export default ProductSEO
