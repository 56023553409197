import React from "react"
import styled from "styled-components"

import { Modal } from "../../../components"
import { CoverButton } from "../../../elements"

import { CoverType } from "../../../elements/CoverButton"

interface Props {
  buttonProperties: any
  toggleModal: () => void
  showModal: boolean
}

const CoverTypeModal: React.FC<Props> = ({
  buttonProperties,
  toggleModal,
  showModal,
}) => {
  return (
    <ModalContainer show={showModal} onClose={toggleModal} className="modal">
      <p>
        Do you want <i>whole life</i> or <i>term</i> Platinum Plus Funeral
        cover?
      </p>
      <div className="buttonContainer">
        <CoverButton
          label="Build Whole Life Cover"
          coverType={CoverType.PLATINUM_PLUS_LIFE}
          {...buttonProperties}
        />
        <CoverButton
          label="Build Term Cover"
          coverType={CoverType.PLATINUM_PLUS_TERM}
          {...buttonProperties}
        />
      </div>
    </ModalContainer>
  )
}

export default CoverTypeModal

const ModalContainer = styled(Modal)`
  &.modal .contentContainer {
    display: flex;
    flex-direction: column;
    gap: 40px;

    > p {
      text-align: center;
    }

    > .buttonContainer {
      align-self: center;
      align-content: center;
      display: grid;
      grid-template-columns: 100%;
      gap: 20px;
      justify-items: center;

      .coverButton {
        width: 100%;
      }
    }

    @media screen and (min-width: ${props => props.theme.breakpoints.md}) {
      > .buttonContainer {
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }
`
